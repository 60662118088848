<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="54"
    :class="{ appHeader: true, 'dropdown-open': isDropdownOpen }"
  >
    <v-btn
      class="mr-3 toggleBtn"
      min-width="0"
      text
      small
      @click="toggleDrawer"
    >
      <Icons
        v-if="drawer"
        name="hamburger-left-icon"
      />
      <Icons
        v-else
        name="hamburger-right-icon"
      />
    </v-btn>
    <!-- :show="breadCrumbs" -->
    <bread-crumbs />

    <v-toolbar-title class="hidden-sm-and-down font-weight-light dnone">
      <span
        v-if="$route.meta.parent_name && $route.meta.parent_name.length > 0"
      >
        <span
          v-for="(pName, i) in $route.meta.parent_name"
          :key="i"
        >
          {{ $t(pName) }}
          <v-icon>mdi-chevron-right</v-icon>
        </span>
      </span>
      <span>
        {{ $t($route.name) }}
      </span>
    </v-toolbar-title>

    <!-- <div class="locale-changer mx-10">
      <v-select
        style="width: 70px"
        hide-details
        v-model="currentLang"
        :items="langs"
      ></v-select>
    </div> -->
    <v-spacer />

    <!-- <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
            <v-btn class="mt-n2" elevation="1" fab small>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </template>
    </v-text-field> -->

    <!-- <div class="mx-3" /> -->
    <!-- <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-bell</v-icon>
    </v-btn> -->

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-store</v-icon>
          <span class="px-2"> {{ 'Orko' }}</span>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div
          v-for="store in stores"
          :key="store.id"
          @click="updateStore(store)"
        >
          <app-bar-item>
            <v-list-item-title v-text="store.name" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->
    <div
      class="topUserInfoWrap"
      min-width="0"
      text
    >
      <Icons
        class="userIcon"
        name="user-icon"
      />
      <span class="topUserInfoText">{{ user.email }}</span>
    </div>
    <div
      class="ml-0 currencydropdownWrap"
      min-width="0"
      text
    >
      <CurrencyDropDown @toggle-header-class="updateHeaderClass" />
    </div>

    <v-btn
      min-width="0"
      text
      class="notifyBellBtn mr-0"
      @click="setNotifyDrawer(!notifyDrawer)"
    >
      <v-badge
        v-if="listLen"
        overlap
        color="primary"
        :content="listLen"
      >
        <Icons name="bell-icon" />
      </v-badge>
      <Icons
        v-else
        name="bell-icon"
      />
    </v-btn>
    <v-btn
      class="ml-0 logoutBtn"
      min-width="0"
      text
      @click="logout"
    >
      <Icons name="logout-icon" />
    </v-btn>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",
  components: {
    CurrencyDropDown: () => import("./CurrencyDropDown.vue"),
    BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    Icons: () => import("@/components/base/icons.vue"),
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentLang: "fr",
    langs: [
      { text: "FR", value: "fr" },
      { text: "EN", value: "en" },
    ],
    isDropdownOpen: false,
  }),

  mounted() {
    this.currentLang = localStorage.currentLang
      ? localStorage.currentLang
      : this.$i18n.locale;

    // Ensure the default state is drawerClose
    if (!this.drawer) {
      document.body.classList.add("drawerClose");
    } else {
      document.body.classList.add("drawerOpen");
    }

    this.$router.afterEach(() => {
      this.handleRouteChange();
    });
  },

  watch: {
    currentLang(newLang) {
      localStorage.currentLang = newLang;
      this.$i18n.locale = newLang;
    },
    drawer(newVal) {
      const body = document.body;
      if (newVal) {
        body.classList.add("drawerOpen");
        body.classList.remove("drawerClose");
      } else {
        body.classList.add("drawerClose");
        body.classList.remove("drawerOpen");
      }
    },
  },

  computed: {
    ...mapGetters({
      list: "alertsAndNotify/getNotifyList",
    }),
    listLen() {
      return this.list.filter((r) => r?.isRead == false)?.length;
    },
    ...mapState(["drawer", "notifyDrawer"]),
    user() {
      return this.$store.getters.getUser;
    },
    stores() {
      return [];
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setNotifyDrawer: "SET_NOTIFY_DRAWER",
    }),
    updateHeaderClass(isOpen) {
      this.isDropdownOpen = isOpen;
    },
    toggleDrawer() {
      this.setDrawer(!this.drawer);

      const body = document.body;
      if (this.drawer) {
        body.classList.remove("drawerClose");
        body.classList.add("drawerOpen");
      } else {
        body.classList.remove("drawerOpen");
        body.classList.add("drawerClose");
      }
    },
    handleNavClick() {
      // Close the drawer and ensure the appropriate class
      this.setDrawer(false);
      document.body.classList.add("drawerClose");
      document.body.classList.remove("drawerOpen");
    },

    handleRouteChange() {
      // Maintain drawer state during route changes
      if (this.drawer) {
        document.body.classList.add("drawerOpen");
        document.body.classList.remove("drawerClose");
      } else {
        document.body.classList.add("drawerClose");
        document.body.classList.remove("drawerOpen");
      }
    },
    async updateStore(store) {
      try {
        await this.$store.dispatch("updateMyStore", {
          next_store_id: store.id,
        });
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    logout() {
      this.$store.dispatch("logout");
      this.$store.dispatch("checkAuth");
    },
  },
};
</script>
<style lang="sass" scoped>
$primaryColor: #23BDAA
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif
#app-bar
  // position: sticky
  // top: 0
  :deep .v-toolbar__content
    background: #FFF

.appHeader
  position: fixed
  padding-left: 256px
  left : 0 !important
  z-index: 9
  :deep .v-toolbar__content
    box-shadow: 0 0 0 !important
    border-bottom:2px solid $primaryColor
  &.dropdown-open
    z-index: 6

.drawerOpen
  .appHeader
    padding-left: 60px

.appHeader
  .v-btn:before
    background-color: transparent !important
    display: none
  .v-bnt:focus
    background-color: transparent !important
    box-shadow: 0 0 0
  .toggleBtn
    border: 0px solid #dadada !important
    padding: 1.2rem 0rem !important
    background: transparent !important
    :deep .v-btn__content
      .lg span svg
        width: 20px
        height: 20px

  .notifyBellBtn
    border: 0px solid #dadada
    height: auto
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    margin-left: 1.2rem !important
    :deep .v-btn__content
      .lg span svg
        width: 16px
        height: 16px

  .logoutBtn
    border: 0px solid #dadada
    padding: 0
    height: auto
    display: flex
    align-items: center
    justify-content: center
    margin-left: 1.6rem !important
    :deep .v-btn__content
      .lg span svg
        width: 16px
        height: 16px

  .topUserInfoWrap
    display: flex
    align-items: center
    padding: 0 1.2rem 0 0
    :deep .userIcon span svg
      width: 20px !important
      height: 20px !important
      margin-right: 0.3rem
    .topUserInfoText
      font-size: 0.875rem
      color: #000
      font-family: $poppinMed

.v-sheet .v-toolbar__content .v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab),
.v-sheet .v-toolbar__content .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab)
  margin-bottom: 0px !important
  padding: 0px 0px !important
  background-color: transparent

#app-bar :deep .v-badge__badge
  font-size: 0
  padding: 0
  min-width: 7px
  height: 7px
  width: 7px
  inset: unset !important
  top: -2px !important
  right: -3px !important
</style>
